import React, { useState } from 'react'
import { StyleSheet, View, ScrollView, Linking } from 'react-native'

import { TextInput } from 'react-native-paper'
import { Button } from 'react-native-material-ui'

const RequestNewListing = ({ navigation }) => {
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [number, setNumber] = useState('')
  const url = `mailto:rc.directory1@gmail.com?subject=${encodeURIComponent(
    'Request Additional Residential Information for Richland County Directory'
  )}&body=Name: ${encodeURIComponent(`${name}\n`)}Address: ${encodeURIComponent(
    `${address}\n`
  )}Phone Number: ${encodeURIComponent(`${number}`)}`
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <TextInput
        mode='outlined'
        label='Name'
        onChangeText={name => setName(name)}
        defaultValue={name}
      />
      <TextInput
        mode='outlined'
        label='Address'
        onChangeText={address => setAddress(address)}
        defaultValue={address}
      />
      <TextInput
        mode='outlined'
        label='Number'
        onChangeText={number => setNumber(number)}
        defaultValue={number}
      />
      <Button
        primary
        raised
        disabled={!Linking.canOpenURL(url)}
        icon='send'
        onPress={() => {
          Linking.openURL(url)
        }}
        text='Send Request'
      />
    </ScrollView>
  )
}

export default RequestNewListing

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column'
  },
  imageContainer: {
    margin: 5,
    flex: 1
  },
  image: {
    flex: 1,
    height: undefined,
    width: undefined
  }
})
