import React from 'react'
import { FlatList, StyleSheet, View, TouchableOpacity } from 'react-native'
import { Icon } from 'react-native-material-ui'

import { Text } from 'react-native-paper'
import { Divider } from 'react-native-material-ui'

import availableEntries from '../../assets/data/entries.json'

function escapeRegExp (string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

const Entries = ({
  navigation,
  route: { params: { category, name, residential, business } = {} } = {}
}) => {
  let entries = []
  if (category) {
    entries = availableEntries.filter(entry => {
      return (entry.categories || []).indexOf(category) > -1
    })
  } else if (name === '') {
    entries = availableEntries
  } else if (name) {
    const regexpString = escapeRegExp(name.trim())
      .replace(/ /g, '|')
      .replace(/\|\|/g, '|')
    const compiledRegExp = new RegExp(regexpString, 'gi')

    entries = availableEntries.filter(entry => {
      if (!compiledRegExp.exec(entry.name)) {
        return false
      }

      // contains at least one of the words
      // check position of each chunk exists

      let toReturn =
        (residential && entry.type === 'w') || (business && entry.type === 'y')

      let terms = name
        .trim()
        .toLowerCase()
        .split(' ')

      terms.forEach(term => {
        if (entry.name.toLowerCase().indexOf(term) < 0) {
          toReturn = false
        }
      })

      return toReturn
    })
  }

  entries = entries

  return (
    <View style={styles.container}>
      <FlatList
        data={entries}
        keyExtractor={item => {
          return item.type + item.name
        }}
        ItemSeparatorComponent={Divider}
        renderItem={({ item }) => {
          const phones = (item.lines || []).filter(line => {
            return line.type == 'phone'
          })

          const hyperlinks = (item.lines || []).filter(line => {
            return line.type == 'hyperlink'
          })
          let hasHyperlink = hyperlinks.length > 0

          let phone = ''
          if (phones.length > 0) {
            phone = phones[0].description
          }

          return (
            <TouchableOpacity
              style={styles.item}
              onPress={() => {
                navigation.navigate('Entry', { entry: item })
              }}
            >
              <View style={styles.verticalCentered}>
                <Text>{item.name}</Text>
              </View>
              <View style={styles.numberContainer}>
                {hasHyperlink && (
                  <View style={styles.verticalCentered}>
                    <Icon name='web' />
                  </View>
                )}
                <View style={styles.verticalCentered}>
                  <Text>{phone}</Text>
                </View>
              </View>
            </TouchableOpacity>
          )
        }}
      />
    </View>
  )
}

export default Entries

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 22
  },
  numberContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row'
  },
  verticalCentered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  item: {
    padding: 10,
    fontSize: 18,
    height: 44,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
})
