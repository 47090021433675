import { StatusBar } from 'expo-status-bar'

import React, { useState, useCallback } from 'react'
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  Linking,
  TouchableOpacity
} from 'react-native'

import { debounce } from 'lodash'

import { Text, Switch, TextInput } from 'react-native-paper'
import { Button } from 'react-native-material-ui'

import cover from '../../assets/cover.png'

const Home = ({ navigation }) => {
  const [text, setText] = useState('')
  const [residential, setResidential] = useState(true)
  const [business, setBusiness] = useState(true)

  const searchAvailable = text && (business || residential)

  const searchIfAvailable = () => {
    if (searchAvailable) {
      navigation.navigate('Entries', { name: text, residential, business })
    }
  }

  const swapResidential = () => {
    setResidential(!residential)
  }

  const handleSwapResidential = useCallback(debounce(swapResidential, 10), [
    residential
  ])

  const swapBusiness = () => {
    setBusiness(!business)
  }

  const handleSwapBusiness = useCallback(debounce(swapBusiness, 10), [business])

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.imageContainer}>
        <Image style={styles.image} resizeMode='contain' source={cover} />
      </View>
      <StatusBar style='auto' />
      <TextInput
        mode='outlined'
        label='Search By Name'
        text
        onChangeText={text => setText(text)}
        defaultValue={text}
        onSubmitEditing={searchIfAvailable}
        right={
          <TextInput.Icon
            disabled={!searchAvailable}
            name='magnify'
            onPress={searchIfAvailable}
          />
        }
      />

      <TouchableOpacity
        style={{ display: 'flex', flexDirection: 'row' }}
        onPress={handleSwapResidential}
      >
        <Switch value={residential} onValueChange={handleSwapResidential} />
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Text>Include Residential Listings</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        style={{ display: 'flex', flexDirection: 'row' }}
        onPress={handleSwapBusiness}
      >
        <Switch value={business} onValueChange={handleSwapBusiness} />
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Text style={{ textAlignVertical: 'center' }}>
            Include Business Listings
          </Text>
        </View>
      </TouchableOpacity>
      <Button
        style={{ container: styles.button }}
        primary
        raised
        icon='search'
        disabled={!searchAvailable}
        onPress={searchIfAvailable}
        text='Search'
        // title='Search'
      />
      <Button
        style={{ container: styles.button }}
        primary
        raised
        icon='list'
        onPress={() => {
          navigation.navigate('Categories')
        }}
        text='View Categories'
        // title='View Categories'
      />
      <Button
        style={{ container: styles.button }}
        // accent
        raised
        icon='person'
        onPress={() => {
          navigation.navigate('Request New Listing')
        }}
        text='Request Additional Residential Listing Be Added'
      />
      <Button
        style={{ container: styles.button }}
        // accent
        raised
        icon='info'
        onPress={() => {
          Linking.openURL(
            `mailto:rc.directory1@gmail.com?subject=${encodeURIComponent(
              'Request Additional Advertising Information for Richland County Directory'
            )}&body=${encodeURIComponent(
              'Hello,\n\nI am interested in finding out more information about advertising in the Richland County Telephone Directory. \n\n Thank you for your time.'
            )}`
          )
        }}
        text='Find Out About Advertising'
      />
    </ScrollView>
  )
}

export default Home

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    margin: 5
  },
  imageContainer: {
    margin: 5,
    flex: 1
  },
  button: {
    margin: 5
  },
  image: {
    flex: 1,
    height: undefined,
    width: undefined
  }
})
