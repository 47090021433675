import React from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import { Text } from 'react-native-paper'
import { Divider } from 'react-native-material-ui'

import availableCategories from '../../assets/data/categories.json'

const Categories = ({ navigation }) => {
  return (
    <View style={styles.container}>
      <FlatList
        data={availableCategories}
        keyExtractor={item => {
          return item
        }}
        ItemSeparatorComponent={Divider}
        renderItem={({ item }) => (
          <Text
            style={{
              text: {
                textAlign: 'left'
              }
            }}
            primary
            onPress={() => {
              navigation.navigate('Entries', {
                category: item
              })
            }}
            style={styles.item}
            // text={item}
          >
            {item}
          </Text>
        )}
      />
    </View>
  )
}

export default Categories

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 22
  },
  item: {
    padding: 10,
    fontSize: 18,
    height: 44
  }
})
