import 'react-native-gesture-handler'
import React from 'react'

import { View } from 'react-native'

import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { Provider as PaperProvider } from 'react-native-paper'

import Home from './components/views/Home'
import Categories from './components/views/Categories'
import Entries from './components/views/Entries'
import Entry from './components/views/Entry'
import RequestNewListing from './components/views/RequestNewListing'

const Stack = createStackNavigator()

export default function App () {
  return (
    <NavigationContainer>
      <PaperProvider>
        <Stack.Navigator>
          <Stack.Screen name='Home' component={Home} />
          <Stack.Screen name='Categories' component={Categories} />
          <Stack.Screen
            name='Entries'
            component={Entries}
            options={({ route }) => {
              const {
                params: { category, name = '', business, residential }
              } = route
              const arrayOfTypes = []
              if (business) {
                arrayOfTypes.push('Business')
              }
              if (residential) {
                arrayOfTypes.push('Residential')
              }
              return {
                title:
                  category ||
                  `Searched: ${name || ''} in ${arrayOfTypes.join(' & ')}`
              }
            }}
          />
          <Stack.Screen
            name='Entry'
            component={Entry}
            options={({ route }) => ({ title: route.params.entry.name })}
          />
          <Stack.Screen
            name='Request New Listing'
            component={RequestNewListing}
          />
        </Stack.Navigator>
      </PaperProvider>
    </NavigationContainer>
  )
}
