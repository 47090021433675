import React, { useState } from 'react'
import { FlatList, StyleSheet, View, Linking, Clipboard } from 'react-native'
import { Button } from 'react-native-material-ui'
import { Snackbar } from 'react-native-paper'

const Entry = ({ route: { params: { entry: { name, lines } } = {} } = {} }) => {
  let [showCopiedTextMessage, setShowCopiedTextMessage] = useState(false)

  let [textCopied, setTextCopied] = useState('')

  const onToggleSnackBar = () =>
    setShowCopiedTextMessage(!showCopiedTextMessage)
  const onShowSnackBar = () => setShowCopiedTextMessage(true)

  const onDismissSnackBar = () => setShowCopiedTextMessage(false)

  const phones = (lines || []).filter(line => {
    return line.type == 'phone'
  })
  const addresses = (lines || []).filter(line => {
    return line.type == 'address'
  })
  const others = (lines || []).filter(line => {
    return line.type == 'extra'
  })

  const hyperlinks = (lines || []).filter(line => {
    return line.type == 'hyperlink'
  })

  const organizedLines = [...phones, ...addresses, ...others, ...hyperlinks]
  return (
    <View style={styles.container}>
      <FlatList
        data={organizedLines}
        keyExtractor={item => {
          return item.type + item.description
        }}
        renderItem={({ item: { type, description, hyperlink } }) => {
          let uri
          let icon
          let raised = true
          switch (type) {
            case 'phone':
              let toDial = description.replace(/[^\d]/g, '')
              if (toDial.length < 10) {
                toDial = `608${toDial}`
              }
              uri = `tel:${toDial}`
              icon = 'phone'
              break
            case 'address':
              uri = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                description
              )}`

              icon = 'room'
              break
            case 'hyperlink':
              uri = hyperlink
              icon = 'web'
              raised = false
              break
            default:
              // do nothing
              icon = 'content-copy'
          }

          return (
            <View style={styles.item}>
              <Button
                primary
                raised={raised}
                icon={icon}
                onPress={() => {
                  if (uri && Linking.canOpenURL(uri)) {
                    Linking.openURL(uri)
                  } else {
                    Clipboard.setString(description)
                    setTextCopied(description)
                    setShowCopiedTextMessage(true)
                  }
                }}
                text={'' + description}
              />
            </View>
          )
        }}
      />
      <Snackbar
        visible={showCopiedTextMessage}
        onDismiss={onDismissSnackBar}
        duration={500}
      >
        Copied: {textCopied}
      </Snackbar>
    </View>
  )
}
export default Entry
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 22
  },
  item: {
    padding: 10
  }
})
